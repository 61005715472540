var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[(_vm.label)?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 caption"},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):_vm._e(),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-card',{class:{
        'd-inline-flex': !_vm.block,
        'd-flex': _vm.block,
        'flex-nowrap': true,
        'align-center': true,
        'justify-center': true},style:({width: '100%', 'border-color': _vm.error ? 'red' : ''}),attrs:{"outlined":"","disabled":_vm.frozen}},[_c('date-picker-datetime',{attrs:{"value":_vm.start,"max":_vm.end,"solo":"","dense":"","hide-details":"","flat":"","text-center":"","clearable":_vm.clearable,"empty-text":_vm.emptyTextBegin ? _vm.emptyTextBegin : _vm.emptyText,"frozen":_vm.frozen || _vm.frozenBegin,"disabled":_vm.frozenBegin},on:{"input":_vm.changedStart}}),_c('p',{class:{
      'mb-0': true,
      'align-self-center': true,
      'text-center': true,
    }},[_vm._v(" ～ ")]),_c('date-picker-datetime',{attrs:{"value":_vm.end,"min":_vm.start,"solo":"","dense":"","hide-details":"","flat":"","text-center":"","clearable":_vm.clearable,"empty-text":_vm.emptyTextEnd ? _vm.emptyTextEnd : _vm.emptyText,"frozen":_vm.frozen || _vm.frozenEnd,"disabled":_vm.frozenEnd},on:{"input":_vm.changedEnd}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }