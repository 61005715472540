var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticStyle:{"min-width":"auto !important"},attrs:{"close-on-content-click":false,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({class:{
        'd-inline-block': _vm.inline,
        'input-text-center': _vm.textCenter
      },style:({
        width: _vm.width,
      }),attrs:{"value":_vm.displayDate,"outlined":_vm.outlined && !_vm.frozen,"solo":_vm.solo || _vm.frozen,"flat":_vm.flat || _vm.frozen,"label":_vm.label,"readonly":"","dense":_vm.dense,"hide-details":_vm.hideDetails && _vm.hint === '',"disabled":_vm.disabled,"placeholder":_vm.emptyText,"hint":_vm.hint,"persistent-hint":_vm.hint !== '',"suffix":_vm.suffix,"background-color":"transparent","error":_vm.error},scopedSlots:_vm._u([(_vm.isClearable)?{key:"append",fn:function(){return [_c('base-button',{attrs:{"small":"","icon":"fas fa-times"},on:{"click":_vm.clear}})]},proxy:true}:null],null,true)},_vm.frozen ? undefined : on))]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-date-picker',{attrs:{"value":this.computedDate,"type":_vm.type,"allowed-dates":_vm.allowedDates,"max":_vm.maxVal,"min":_vm.minVal,"day-format":_vm.dayFormat},on:{"input":_vm.changed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }